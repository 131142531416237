<template>
    <CreateUpdateModal
        :visible="visible"
        :title="title"
        :to-link="`${getCategorySlug}${form.slug}/`"
        :loading="loading"
        @closeActionHandler="closeActionHandler"
        @saveActionHandler="saveActionHandler"
        @saveOnlyActionHandler="saveOnlyActionHandler"
    >
        <form class="edit-form">
            <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                <Preloader />
            </div>

            <div class="grid grid-cols-12 gap-6 mt-4">
                <div class="relative col-span-6">
                    <label for="form-name" class="form-label">
                        Название
                        <sup v-if="v$.form.name.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.name.$errors.length" for="form-name" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.name.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-name"
                        v-model="form.name"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.name.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                        :data-value="form.name"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-category_id" class="form-label">Категория</label>
                    <select
                        id="form-category_id"
                        v-model="form.category_id"
                        name="type"
                        class="form-select sm:mr-2"
                        autocomplete="off"
                    >
                        <option value="">Нет</option>
                        <option
                            v-for="(category, optIdx) in categories"
                            :key="optIdx"
                            :value="category.id"
                            :selected="form.category_id === category.id"
                        >
                            {{ category.name }}
                        </option>
                    </select>
                </div>

                <div class="relative col-span-6">
                    <label for="form-header" class="form-label">
                        Заголовок
                        <sup v-if="v$.form.header.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.header.$errors.length" for="form-header" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.header.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-header"
                        v-model="form.header"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.header.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                        :data-value="form.header"
                    />
                </div>

                <div class="col-span-6">
                    <label for="form-sub_header" class="form-label">Подзаголовок</label>
                    <input
                        id="form-sub_header"
                        v-model="form.sub_header"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                        :data-value="form.sub_header"
                    />
                </div>

                <div class="relative col-span-4">
                    <label for="form-slug" class="form-label">
                        Ссылка
                        <sup v-if="v$.form.slug.required" class="text-theme-6">*</sup>
                    </label>
                    <label v-if="v$.form.slug.$errors.length" for="form-slug" class="form-input-text-error">
                        {{ getOverrideMessage(v$.form.slug.$errors[0].$message) }}
                    </label>
                    <input
                        id="form-slug"
                        v-model="form.slug"
                        type="text"
                        class="form-control w-full"
                        :class="{ 'form-input-error': v$.form.slug.$errors.length }"
                        placeholder=""
                        autocomplete="off"
                        :data-value="form.slug"
                    />
                </div>

                <div class="col-span-4">
                    <label for="form-date" class="form-label">Дата публикации</label>
                    <input
                        id="form-date"
                        v-model="form.date"
                        type="date"
                        class="form-control w-full"
                        autocomplete="off"
                    />
                </div>

                <div class="col-span-4">
                    <label for="form-published" class="form-label">Опубликовано</label>
                    <select id="form-published" v-model="form.published" class="form-select">
                        <option value="1">Опубликовано</option>
                        <option value="0">Не опубликовано</option>
                    </select>
                </div>

                <div class="col-span-12">
                    <label for="form-faq_ids" class="form-label">Вопросы и ответы</label>
                    <Multiselect
                        id="form-faq_ids"
                        v-model="form.faq_ids"
                        mode="tags"
                        placeholder="Выберите несколько вопросов"
                        track-by="question"
                        label="question"
                        value-prop="id"
                        :hide-selected="false"
                        :close-on-select="false"
                        :searchable="true"
                        :options="faq"
                        no-results-text="Не найдено"
                        no-options-text="Не найдено"
                        :native="false"
                    >
                        <template #option="{ option }">
                            {{ option.question }}
                        </template>
                    </Multiselect>
                </div>

                <div class="relative col-span-12">
                    <label class="form-label">Предварительный текст</label>
                    <CustomWyswig v-model="form.intro_text" />
                </div>

                <div class="relative col-span-12">
                    <label class="form-label">Полный текст</label>
                    <CustomWyswig v-model="form.full_text" />
                </div>

                <div class="col-span-12">
                    <label class="form-label">Изображение</label>
                    <UploadImageNew
                        ref="update_image"
                        v-model="form.image"
                        v-model:delete="form.image_delete"
                        recommended-sizes="1170х300px"
                    />
                </div>

                <div class="col-span-12">
                    <h4 class="font-bold text-xl mb-3">Seo</h4>
                    <label for="form-seo_title" class="form-label">Seo заголовок</label>
                    <input
                        id="form-seo_title"
                        v-model="form.seo_title"
                        type="text"
                        class="form-control w-full"
                        placeholder=""
                        autocomplete="off"
                        :data-value="form.seo_title"
                    />
                </div>

                <div class="col-span-12">
                    <label class="form-label">Seo текст</label>
                    <CustomWyswig v-model="form.seo_text" />
                </div>

                <div class="col-span-12" title="SEO тэг для каждого круиза на этой странице">
                    <label for="form-hidden_tag" class="form-label">Скрытые тэги для круизов</label>

                    <Multiselect
                        id="form-hidden_tag"
                        v-model="form.hidden_tag"
                        :options="form.hidden_tag"
                        mode="tags"
                        placeholder="SEO тэги для каждого круиза на этой странице"
                        :hide-selected="false"
                        :close-on-select="false"
                        :native="false"
                        :searchable="true"
                        :create-option="true"
                        no-results-text="Введите новый тэг и Enter чтобы создать"
                        no-options-text="Введите новый тэг и Enter чтобы создать"
                    />
                </div>

                <div class="col-span-12" title="SEO тэг для каждого отзыва на этой странице">
                    <label for="form-hidden_tag_review" class="form-label">Скрытые тэги для отзывов</label>

                    <Multiselect
                        id="form-hidden_tag_review"
                        v-model="form.hidden_tag_review"
                        :options="form.hidden_tag_review"
                        mode="tags"
                        placeholder="SEO тэги для каждого отзыва на этой странице"
                        :hide-selected="false"
                        :close-on-select="false"
                        :native="false"
                        :searchable="true"
                        :create-option="true"
                        no-results-text="Введите новый тэг и Enter чтобы создать"
                        no-options-text="Введите новый тэг и Enter чтобы создать"
                    />
                </div>

                <div class="col-span-12" title="SEO тэг для каждого теплохода на этой странице">
                    <label for="form-hidden_tag_ship" class="form-label">Скрытые тэги для теплоходов</label>

                    <Multiselect
                        id="form-hidden_tag_ship"
                        v-model="form.hidden_tag_ship"
                        :options="form.hidden_tag_ship"
                        mode="tags"
                        placeholder="SEO тэги для каждого теплохода на этой странице"
                        :hide-selected="false"
                        :close-on-select="false"
                        :native="false"
                        :searchable="true"
                        :create-option="true"
                        no-results-text="Введите новый тэг и Enter чтобы создать"
                        no-options-text="Введите новый тэг и Enter чтобы создать"
                    />
                </div>

                <div class="col-span-12">
                    <label class="form-label font-bold">Акции</label>
                    <p v-if="promotionOptions.length === 0">Отсутствуют</p>
                    <MultiselectTable
                        v-else
                        v-model="form.promotions"
                        :header-labels="promotionTableLabels"
                        :options="promotionOptions"
                        :track-by="promotionMultiselectTrackBy"
                    />
                </div>

                <div class="col-span-12">
                    <label class="form-label font-bold">Кастомные поля</label>
                    <TeamsTable v-model="seo" :meta="null" :headers="seoHeaders" @update:modelValue="updateSeo" />
                </div>
            </div>

            <div v-if="!successfullyLoaded">Не удалось загрузить данные :(</div>
        </form>
    </CreateUpdateModal>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import CreateUpdateModal from '@/components/modals/CreateUpdateModal';
import Preloader from '@/components/preloader/Main';
import UploadImageNew from '@/components/upload-image/UploadImageNew.vue';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import TeamsTable from '@/components/tables/TeamsTable';
import MultiselectTable from '@/components/tables/MultiselectTable';
import { errorResponse } from '@/mixins/form/form-mixin';
import { helper as $h } from '@/utils/helper';
import { formPreparation } from '@/utils/object-to-form-data';
import { pagesMixin } from '@/mixins/form/pages-mixin';
import Multiselect from '@vueform/multiselect';

export default {
    name: 'EditPageForm',
    components: {
        CreateUpdateModal,
        Multiselect,
        Preloader,
        UploadImageNew,
        CustomWyswig,
        TeamsTable,
        MultiselectTable,
    },
    mixins: [errorResponse, pagesMixin],
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: 'Редактирование',
        },
        entityId: {
            type: Number,
            required: true,
        },
    },
    emits: ['saveActionHandler', 'closeActionHandler'],
    setup() {
        return { v$: useVuelidate() };
    },
    computed: {
        getCategorySlug() {
            const currentCategory = this.categories.filter((category) => category.id === this.form.category_id)[0];

            if (!currentCategory) return '';

            return `${currentCategory.slug}/`;
        },
        getFaq() {
            console.log(this.form.faqIds);

            return '';
        },
    },
    validations() {
        return {
            form: {
                name: { required },
                header: { required },
                slug: { required },
            },
        };
    },
    watch: {
        // отслеживаем, когда форму открывают или закрывают
        visible: function (visible) {
            if (visible) {
                // форма открыта, грузим данные
                this.fetchPageData(this.entityId);
            } else {
                // очищаем поля
                this.resetForm();
                this.successfullyLoaded = true;
            }
        },
    },
    methods: {
        getFormSeoID(code) {
            return `form-seo_${code}`;
        },
        getDefaultSeo() {
            return [
                { code: 'title', value: '' },
                { code: 'keywords', value: '' },
                { code: 'description', value: '' },
            ];
        },
        async fetchPageData(entityId) {
            this.loading = true;
            try {
                const { data } = await this.axios.get(`/pages/${entityId}/edit`);

                if (!data.success || data.success !== true) {
                    this.successfullyLoaded = false;
                    return;
                }

                this.form = { ...data.data, seo: data.data.seo ?? this.seo };
                this.seo = data.data.seo ?? this.seo;

                this.form.faq_ids = data.data?.faq?.map(({ id }) => id);

                this.categories = data.categories;
                this.promotionOptions = data.promotions;
            } catch (ex) {
                console.log(ex);
            }
            this.loading = false;
        },
        async save() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;
            this.form.seo = this.seo;
            try {
                const formData = formPreparation(this.form, {
                    nullsAsUndefineds: false,
                    imagesAsObject: true,
                });
                const { data } = await this.axios.post(`/pages/${this.$route.query.id}`, formData, {
                    params: { _method: 'patch' },
                });
                this.$notify(data.message);
                this.loading = false;
                return true;
            } catch (ex) {
                this.errorResponse(ex);
                this.loading = false;
                return false;
            }
        },
        async saveActionHandler() {
            const success = await this.save();
            if (success) this.$emit('saveActionHandler');
        },
        async saveOnlyActionHandler() {
            const success = await this.save();
            if (success) await this.fetchPageData(this.entityId);
        },
        closeActionHandler() {
            console.log('click');
            this.$emit('closeActionHandler');
        },
        resetForm() {
            for (const key of Object.keys(this.form)) {
                this.form[key] = null;
            }

            this.form.extra_fields = [];
            this.form.promotions = [];

            this.resetSeoFields();
        },
        resetSeoFields() {
            this.seo = this.getDefaultSeo();
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
    },
};
</script>

<style scoped lang="scss"></style>
