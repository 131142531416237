export const pagesMixin = {
    mounted() {
        this.getFaqAll();
    },
    data() {
        return {
            loading: false,
            successfullyLoaded: true,
            form: {
                name: '',
                category_id: '',
                header: '',
                sub_header: '',
                hidden_tag: null,
                hidden_tag_review: null,
                hidden_tag_ship: null,
                slug: '',
                date: null,
                image: '',
                image_delete: null,
                intro_text: '',
                full_text: '',
                extra_fields: [
                    // { type: 'text', label: 'Еще одно поле', code: 'code_name', value: null }
                ],
                published: 0,
                promotions: [],
                image_title: null,
                image_alt: null,
                seo_title: null,
                seo_text: null,
                faq_ids: null,
            },
            faq: [
                // {
                //     id: '1',
                //     question: 'sasd',
                // },
            ],
            seo: [
                { code: 'title', value: '' },
                { code: 'keywords', value: '' },
                { code: 'description', value: '' },
            ],
            seoHeaders: [
                { field: 'code', label: 'Название параметра' },
                { field: 'value', label: 'Значение' },
            ],
            promotionTableLabels: ['Акция'],
            promotionMultiselectTrackBy: 'name',
            categories: [],
            promotionOptions: [],
        };
    },

    methods: {
        async getFaqAll() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/faq-all`);
                this.faq = data.data;
            } catch (ex) {
                console.log('cannot fetch point edit params ' + ex);
            } finally {
                this.loading = false;
            }
        },
    },
};
