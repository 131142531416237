<template>
    <div class="timetable">
        <!--    <CruiseContentSearch search-query-key="searchString" :discount-options="discounts" />-->

        <ContentTable
            :headers="tableExample"
            :table-rows="tableData"
            :table-actions="tableActions"
            @edit="editAction"
        />
    </div>
</template>
<script>
import { errorResponse } from '@/mixins/form/form-mixin';
import ContentTable from '@/components/tables/ContentTable';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    name: 'CruiseTimetables',
    components: { ContentTable /*PopupDeleteModal*/ },
    mixins: [errorResponse, pageMixin],
    data() {
        return {
            // modalOpen: false,
            tableExample: [
                { field: 'id', label: '#' },
                { field: 'point_title', label: 'Точка' },
                { field: 'departure_date_time', label: 'Отправление' },
                { field: 'arrival_date_time', label: 'Прибытие' },
            ],
            tableActions: [{ name: 'edit', title: 'редактировать', icon: 'Edit2Icon', cbName: 'edit' }],
            // addButtonDelete: false,
            tableData: [],
            // pagination: null,
            // modalEntityName: null,
            // modalData: null,
        };
    },
    async created() {
        await this.fetchTimetables();
    },
    methods: {
        async fetchTimetables() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/cruises/${this.$route.params.cruise_id}/timetable`);
                this.tableData = data.data;
                this.pagination = data.meta;
                this.discounts = data.discounts;
                this.loading = false;
            } catch (ex) {
                this.loading = false;
                console.log('cant fetch cruises: ' + ex);
            }
        },
        // deleteAction(row) {
        //     this.modalData = row;
        //     this.modalOpen = true;
        // },
        editAction(row) {
            this.modalType = 'update';
            // this.$router.push({
            //     name: 'cruise-timetable-edit',
            //     params: { cruise_id: this.$route.params.cruise_id, cruise_timetable_id: row.id },
            // });
            this._pMTransitionToEditPageWithQuery('cruise-timetable-edit', {
                cruise_id: this.$route.params.cruise_id,
                cruise_timetable_id: row.id,
            });
        },
        // onDeleteModalAccepted(data) {
        //     this.axios
        //         .delete('/cruises/' + data.id)
        //         .then((res) => {
        //             this.fetchCruises(this.$route.query);
        //             this.modalOpen = false;
        //         })
        //         .catch((ex) => {
        //             this.errorResponse(ex);
        //         });
        // },
    },
};
</script>
