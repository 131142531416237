<template>
    <div class="intro-y box p-5 mt-5">
        <form method="post" class="grid grid-cols-12 gap-6" :class="{ 'disabled-form': loading }">
            <div v-if="loading" class="absolute z-100 left-0 top-0 w-full h-full flex items-center justify-center">
                <Preloader />
            </div>

            <div class="relative col-span-6">
                <label for="form-title" class="form-label">
                    Название
                    <sup v-if="v$.form.title.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.title.$errors.length" for="form-title" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.title.$errors[0].$message) }}
                </label>
                <input
                    id="form-title"
                    v-model="form.title"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.title.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="relative col-span-6">
                <label for="form-weight" class="form-label">
                    Вес (Приоритет сортировки)
                    <sup v-if="v$.form.weight.required" class="text-theme-6">*</sup>
                </label>
                <label v-if="v$.form.weight.$errors.length" for="form-weight" class="form-input-text-error">
                    {{ getOverrideMessage(v$.form.weight.$errors[0].$message) }}
                </label>
                <input
                    id="form-weight"
                    v-model="form.weight"
                    type="text"
                    class="form-control w-full"
                    :class="{ 'form-input-error': v$.form.weight.$errors.length }"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="col-span-12">
                <label for="form-description" class="form-label">Описание</label>
                <CustomWyswig id="form-description" v-model="form.description" class="form-control w-full" />
            </div>

            <div class="col-span-6">
                <label class="form-label">Изображение</label>
                <UploadImageNew
                    v-model="form.custom_image"
                    v-model:delete="form.custom_image_delete"
                    recommended-sizes="640x220px"
                ></UploadImageNew>
            </div>

            <div class="col-span-6">
                <label for="form-url" class="form-label">Ссылка на изображение от поставщика API</label>
                <input
                    id="form-url"
                    v-model="form.url"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                    disabled
                />
            </div>

            <div class="col-span-6">
                <label for="form-url" class="form-label">Ширина</label>
                <input
                    id="form-url"
                    v-model="form.lat"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="col-span-6">
                <label for="form-url" class="form-label">Высота</label>
                <input
                    id="form-url"
                    v-model="form.lon"
                    type="text"
                    class="form-control w-full"
                    placeholder=""
                    autocomplete="off"
                />
            </div>

            <div class="col-span-12 flex items-center justify-end mt-5">
                <router-link class="btn btn-secondary w-24 mr-2 mb-2" to="#" @click.prevent="redirectToMainPage"
                    >Отмена</router-link
                >
                <button type="submit" class="btn btn-primary mb-2 mr-2" @click.prevent="save(true)">
                    Сохранить и выйти
                </button>
                <button type="submit" class="btn btn-primary mb-2" @click.prevent="save()">Сохранить</button>
            </div>
        </form>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { required, numeric, minValue, maxValue } from '@vuelidate/validators';
import Preloader from '@/components/preloader/Main';
import UploadImageNew from '@/components/upload-image/UploadImageNew';
import CustomWyswig from '@/components/base/custom-wyswig/Main';
import { pointMixin } from '@/mixins/form/point-mixin';
import { errorResponse } from '@/mixins/form/form-mixin';
import { helper as $h } from '@/utils/helper';
import { formPreparation } from '@/utils/object-to-form-data';
import { pageMixin } from '@/mixins/pageMixin';

export default {
    name: 'Edit',
    components: {
        Preloader,
        UploadImageNew,
        CustomWyswig,
    },
    mixins: [pointMixin, errorResponse, pageMixin],
    setup() {
        return { v$: useVuelidate() };
    },
    validations() {
        return {
            form: {
                title: { required },
                weight: { required, numeric, minValue: minValue(0), maxValue: maxValue(65535) },
            },
        };
    },
    created() {
        this.$store.commit('main/setPageHeader', 'Редактирование локации');
        this.fetchEditParams();
    },
    methods: {
        async save(redirect = false) {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) {
                this.$notify({ text: 'Заполните все поля правильно', type: 'error' });
                return;
            }

            this.loading = true;

            try {
                const formData = formPreparation(this.form, {
                    nullsAsUndefineds: false,
                    imagesAsObject: true,
                });
                const { data } = await this.axios.post('/points/' + this.$route.params.id, formData, {
                    params: { _method: 'patch' },
                });

                this.$notify(data.message);

                if (redirect) {
                    this.redirectToMainPage();
                    // await this.$router.push({ name: 'points' });
                }
            } catch (ex) {
                this.errorResponse(ex);
            } finally {
                this.loading = false;
            }
        },
        async fetchEditParams() {
            this.loading = true;

            try {
                const { data } = await this.axios.get(`/points/${this.$route.params.id}/edit`);

                // заполняем данные
                for (const [key, val] of Object.entries(data.data)) {
                    this.form[key] = val;
                }
            } catch (ex) {
                console.log('cannot fetch point edit params ' + ex);
                // this.$router.push('/error-page');
            } finally {
                this.loading = false;
            }
        },
        getOverrideMessage(message) {
            return $h.getOverrideMessage(message);
        },
        redirectToMainPage() {
            this._pMTransitionToEditPageWithQuery('points');
        },
    },
};
</script>

<style scoped></style>
